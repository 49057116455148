<template>
  <CommonLayout v-if="tournaments.length">
    <div class="cabinet">
      <div class="cabinet__inner">
        <div class="cabinet__head">
          <div class="cabinet__row">
            <div class="cabinet__col">
              <h1 class="title">Турнирные таблицы</h1>
            </div>
          </div>
          <div class="cabinet__row mb-50">
            <div class="cabinet__col">
              <div class="subtitle weight-400 mt-30">
                У вас {{ tournaments.length }} {{ tournamentsAmount }}:
              </div>
              <NavSlider class="mt-10">
                <div
                  v-for="item in tournaments"
                  :key="item.id"
                  class="nav-slider__item"
                >
                  <router-link
                    :to="{ name: 'tournamentTable', query: { id: item.id } }"
                    ><span>{{ item.name }}</span></router-link
                  >
                </div>
              </NavSlider>
            </div>
          </div>
          <div class="cabinet__row mt-30">
            <div class="cabinet__col">
              <div class="tournament-head">
                <div class="tournament-head__row">
                  <div class="tournament-head__group">
                    <div class="tournament-head__group-name">
                      Задание турнира
                    </div>
                    <div class="tournament-head__group-title">
                      {{ tournament.name }}
                    </div>
                    <div class="tournament-head__group-desc">
                      {{ tournament.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cabinet__col">
              <div class="tournament-legend">
                <div class="tournament-legend__inner">
                  <div class="tournament-legend__head">
                    <div class="tournament-legend__title">Информация:</div>
                  </div>
                  <div class="tournament-legend__body">
                    <ul>
                      <li v-if="tournament.createDate">
                        <span>Дата начала</span><i></i
                        ><b>{{ tournament.createDate | formatDate2 }}</b>
                      </li>
                      <li>
                        <span>Кол-во претендентов</span><i></i
                        ><b>{{ teams.length }}</b>
                      </li>
                      <li>
                        <span>Кол-во мест</span><i></i><b>{{ ranks.length }}</b>
                      </li>
                      <li>
                        <span>Занято мест</span><i></i
                        ><b>{{ teamsRewarded.length }}</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="ranks.length" class="cabinet__body">
          <div class="tournament">
            <div class="tournament__title">Распределение мест</div>
            <div class="tournament__body">
              <div class="tournament__content">
                <TournamentPlace
                  v-for="item in ranks"
                  :key="item.id"
                  :place="item.position"
                  :coins="item.reward"
                  :name="item.teamName"
                  :desc="item.accomplishmentDescription"
                  :date="item.completionDate"
                  :isRewarded="item.teamName && item.reward"
                />
              </div>
              <div v-if="teams.length" class="tournament__sidebar">
                <SidebarWrapper :autoHeight="true">
                  <template #head>
                    <div class="subtitle weight-400 mt-0">Все претенденты</div>
                  </template>
                  <template #body>
                    <div class="tournament-list">
                      <div
                        v-for="(item, index) in teams"
                        :key="index"
                        class="tournament-list__item"
                        :class="{
                          active: getTeamRewarded(item.teamName).position,
                        }"
                      >
                        <span>{{ item.teamName }} </span
                        ><span>
                          {{ getTeamRewarded(item.teamName).position }}
                          место</span
                        >
                      </div>
                    </div>
                  </template>
                </SidebarWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
  <CabinetContentLayout
    v-else
    :has-button-back="true"
    :is-loading="loading"
    title="Турнирные таблицы"
  >
    <p>
      В разделе «Турнирные таблицы» вы будете получать командные задачи, <br />
      актуальные для нескольких команд. Чем быстрее ваша команда выполнит<br />
      задачу, тем выше место она займет в турнирной таблице и тем больше <br />
      будет ваша награда.
    </p>
    <p>О начале турнира вам сообщит Совет старейшин.</p>
  </CabinetContentLayout>
</template>

<script>
import { GetTournament, GetTournaments } from "@/api/TournamentTable";
import NavSlider from "@/components/NavSlider.vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import TournamentPlace from "@/components/TournamentPlace.vue";
import CabinetContentLayout from "@/layout/CabinetContentLayout.vue";
import CommonLayout from "@/layout/CommonLayout.vue";
import { declOfNum } from "@/utils";

export default {
  components: {
    CommonLayout,
    CabinetContentLayout,
    TournamentPlace,
    NavSlider,
    SidebarWrapper,
  },
  computed: {
    ranks() {
      return this.tournament.ranks
        .filter((e) => e.position > 0)
        .sort((a, b) => a.position - b.position);
    },
    teams() {
      return this.tournament.ranks.filter(
        (e) => e.teamName && e.position === 0
      );
    },
    teamsRewarded() {
      return this.ranks.filter((e) => e.teamName && e.reward);
    },
    tournamentsAmount() {
      return declOfNum(this.tournaments.length, [
        "командный турнир",
        "командных турнира",
        "командных турниров",
      ]);
    },
  },
  data() {
    return {
      loading: false,
      tournaments: [],
      tournament: {},
    };
  },
  created() {
    const { id } = this.$route.query;

    if (id) {
      this.getTournament(id);
    }

    this.fetchData();
  },
  watch: {
    $route(to) {
      const { id } = to.query;

      if (id) {
        this.getTournament(id);
      }
    },
  },
  methods: {
    async fetchData() {
      const { id } = this.$route.query;
      this.loading = true;

      try {
        const { data } = await GetTournaments();

        this.tournaments = data;

        if (!id && this.tournaments.length) {
          this.setDefaultTournament();
        }
      } catch (error) {
        new Error(error);
      } finally {
        this.loading = false;
      }
    },
    async getTournament(id) {
      try {
        this.loading = true;
        const { data } = await GetTournament({ id });
        this.tournament = data;
      } catch (error) {
        new Error(error);
      } finally {
        this.loading = false;
      }
    },
    setDefaultTournament() {
      this.$router.replace({ query: { id: this.tournaments[0].id } });
    },
    getTeamRewarded(name) {
      return this.teamsRewarded.find((e) => e.teamName === name) || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-legend {
  background-image: url(~@/assets/img/pics/legend-bg-3.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 361px;
  height: 269px;
  &__inner {
    padding: 30px 40px;
  }
  &__head {
    position: relative;
  }
  &__tooltip {
    position: absolute;
    top: 8px;
    right: 100%;
  }
  &__title {
    font-size: 24px;
    color: #50ffeb;
  }
  &__body {
    margin-top: 20px;
    ul {
      padding: 0;
      list-style: none;
      li {
        margin: 10px 0;
        display: flex;
        align-items: baseline;
        font-size: 18px;
        line-height: normal;
        height: 28px;
        color: #ade5d9;
        i {
          flex: 1 1 auto;
          border-bottom: 1px dotted #ade5d9;
          margin: 0 4px;
        }
        b {
          font-weight: normal;
          color: #fff;
        }
      }
    }
  }
}

.tournament {
  margin-top: 25px;
  &__title {
    font-size: 40px;
    color: #50ffeb;
    margin-bottom: 40px;
  }
  &__body {
    display: grid;
    gap: 60px;
    grid-template-columns: auto 458px;
    margin-bottom: 20px;
  }
  &__content {
    display: grid;
    gap: 20px;
    align-self: start;
  }
  &__sidebar {
    display: grid;
    align-self: start;
    position: sticky;
    top: 155px;
  }
}

.tournament-head {
  &__row {
    display: flex;
    align-items: flex-end;
  }
  &__col {
    margin-right: 50px;
  }
  &__tooltip {
    margin-bottom: -8px;
  }
  &__group {
    margin-bottom: 30px;
    padding-right: 30px;
    &-name {
      font-size: 18px;
      color: #ade5d9;
    }
    &-title {
      font-size: 40px;
      line-height: 44px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-desc {
      font-family: $fontRafale;
      font-size: 18px;
      margin-top: 20px;
    }
  }
  &__title {
    font-size: 18px;
    color: #ade5d9;
    display: flex;
    align-items: center;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    color: #50ffeb;
    text-transform: uppercase;
  }
  &__text {
    font-size: 18px;
    color: #ade5d9;
    margin-bottom: 2px;
  }
}

.tournament-list {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='355' height='2' fill='none'%3E%3Cpath fill='%235ABEBE' d='m176.227 1.097 89.367.645c14.888.129 29.776.322 44.703.193 14.888-.193 29.776-.322 44.703-.838V.903c-14.888-.58-29.776-.71-44.703-.839-14.888-.129-29.776.065-44.703.194l-89.367.645v.194Z'/%3E%3Cpath fill='%235ABEBE' d='m0 1.099 110.222.66c18.363.132 36.725.33 55.136.197 18.362-.197 36.725-.33 55.135-.857V.901C202.131.307 183.769.175 165.358.044c-18.362-.132-36.725.065-55.136.197L0 .901V1.1Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0 100%;
  width: 355px;
  &__item {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='355' height='2' fill='none'%3E%3Cpath fill='%235ABEBE' d='m176.227 1.097 89.367.645c14.888.129 29.776.322 44.703.193 14.888-.193 29.776-.322 44.703-.838V.903c-14.888-.58-29.776-.71-44.703-.839-14.888-.129-29.776.065-44.703.194l-89.367.645v.194Z'/%3E%3Cpath fill='%235ABEBE' d='m0 1.099 110.222.66c18.363.132 36.725.33 55.136.197 18.362-.197 36.725-.33 55.135-.857V.901C202.131.307 183.769.175 165.358.044c-18.362-.132-36.725.065-55.136.197L0 .901V1.1Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 20px 12px;
    font-size: 18px;
    font-family: $fontRafale;
    display: flex;
    justify-content: space-between;
    span:nth-child(2) {
      opacity: 0;
    }
    &.active {
      background-color: rgba(0, 255, 254, 0.05);
      span {
        opacity: 0.4;
      }
    }
  }
}
</style>
