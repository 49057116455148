import request from "@/utils/request";

export function GetTournaments(query) {
  return request({
    url: "/api/player/TournamentTable/GetTournaments",
    method: "get",
    params: query,
  });
}

export function GetTournament(query) {
  return request({
    url: "/api/player/TournamentTable/GetTournament",
    method: "get",
    params: query,
  });
}
