<template>
  <div class="sidebar" :class="{ 'auto-height': autoHeight }">
    <div class="sidebar__inner">
      <div class="sidebar__head"><slot name="head" /></div>
      <div class="sidebar__body">
        <div class="sidebar__content">
          <slot name="body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    autoHeight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: relative;
  &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: 50%;
    background-repeat: no-repeat;
    background-image: url(~@/assets/img/pics/sidebar-bg.svg);
    content: "";
  }
  &::after {
    bottom: 0;
    background-position: 0 100%;
  }
  &.auto-height {
    .sidebar__content {
      max-height: calc(100vh - 335px);
    }
  }
  &__inner {
    position: relative;
    z-index: 1;
    padding: 30px;
  }
  &__head {
  }
  &__body {
    margin-top: 30px;
  }
  &__content {
    overflow-y: auto;
    @include scrollbar;
  }
}
</style>
