<template>
  <div
    class="tournament-place"
    :class="{
      gold: place === 1,
      silver: place === 2,
      bronze: place === 3,
      collapsed: collapsed,
    }"
  >
    <div class="tournament-place__bg">
      <div>
        <svg>
          <use :xlink:href="`#tournament-place-bg_${place}`" />
        </svg>
      </div>
      <div>
        <svg>
          <use :xlink:href="`#tournament-place-bg_${place}`" />
        </svg>
      </div>
    </div>
    <div class="tournament-place__info">
      <div class="tournament-place__place">{{ place }} место</div>
      <div class="tournament-place__caption">
        Награда:
        <span class="tournament-place__coins">
          <img
            src="@/assets/img/pic2/coin.svg"
            width="20"
            height="20"
            alt=""
          />{{ coins | priceFilter }}
        </span>
      </div>
    </div>
    <div v-if="isRewarded" class="tournament-place__content">
      <div class="tournament-place__title">Команда «{{ name }}»</div>
      <div
        class="tournament-place__desc"
        :class="{
          fluid: !(desc && desc.length > 80),
        }"
      >
        <strong>Достижение:</strong> {{ desc }}
      </div>
      <div v-if="date" class="tournament-place__date">
        {{ date | formatDate2 }}
      </div>
      <button
        v-if="desc && desc.length > 80"
        class="tournament-place__button"
        @click="toggle"
      >
        {{ collapsed ? "меньше" : "больше" }}
      </button>
    </div>
    <div
      v-else
      class="tournament-place__content tournament-place__content_skeleton"
    >
      <div class="tournament-place__text">Вы можете занять это место</div>
    </div>
    <svg width="722" height="366" fill="none" style="display: none">
      <g :id="`tournament-place-bg_${place}`">
        <path
          fill="#020711"
          fill-opacity=".5"
          fill-rule="evenodd"
          d="M23 1.676c-8.837 0-16 7.163-16 16v325.87c0 8.837 7.163 16 16 16h676c8.837 0 16-7.163 16-16V17.676c0-8.837-7.163-16-16-16H397.031l-3.657 4.194h-64.686l-3.657-4.194H23Z"
          clip-rule="evenodd"
        />
        <path
          class="border"
          fill="#5CFFED"
          fill-rule="evenodd"
          d="M716.768 10.569 722 14.44v6.533l-.5-.37V345c0 11.322-9.178 20.5-20.5 20.5H587v-1h114c10.77 0 19.5-8.731 19.5-19.5V19.863l-5.11-3.781c.073.629.11 1.269.11 1.918v325c0 9.113-7.387 16.5-16.5 16.5H57.83l-.94.714H12.995l-8.1-6.149v-4.595L.159 346H0V215h1v124.976l5.532 4.06A16.75 16.75 0 0 1 6.5 343V18C6.5 8.887 13.887 1.5 23 1.5h300.495c1.3 0 2.537.562 3.391 1.542l.984 1.128a3.498 3.498 0 0 0 2.638 1.2h61.047a3.501 3.501 0 0 0 2.638-1.2l.983-1.128a4.5 4.5 0 0 1 3.392-1.542h265.461l1.962-1.5h42.866l7.911 6.048v4.52ZM7.606 344.825A15.677 15.677 0 0 1 7.5 343V18C7.5 9.44 14.44 2.5 23 2.5h300.495c1.011 0 1.973.437 2.638 1.2l.983 1.127a4.499 4.499 0 0 0 3.392 1.543h61.047c1.3 0 2.537-.563 3.392-1.543l.983-1.127a3.498 3.498 0 0 1 2.638-1.2h264.153L661 3.816h42.066l4.829 3.691v3.03l6.36 4.705c.161.895.245 1.817.245 2.758v325c0 8.56-6.94 15.5-15.5 15.5H59.148L62 356.335H18.925l-4.945-3.753v-3.079l-6.374-4.678ZM332 2.175h60v-1h-60v1Z"
          clip-rule="evenodd"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ["place", "coins", "name", "desc", "date", "isRewarded"],
  data() {
    return {
      collapsed: false,
    };
  },
  created() {},
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-place {
  width: 722px;
  position: relative;
  left: -6px;
  display: grid;
  grid-template-columns: 1fr 435px;
  padding: 16px 20px 20px 20px;
  &.collapsed {
    .tournament-place__desc {
      -webkit-line-clamp: initial;
    }
  }
  &.gold {
    .tournament-place__place {
      background: linear-gradient(
        33.45deg,
        #ba5900 22.39%,
        #f1ae31 89.57%,
        #ba5900 143.83%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      user-select: none;
    }
  }
  &.silver {
    .tournament-place__place {
      background: linear-gradient(
        52.53deg,
        #464646 -10%,
        #b4b4b4 15.05%,
        #f0efef 42.8%,
        #b4b4b4 65.81%,
        #464646 85.44%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      user-select: none;
    }
  }
  &.bronze {
    .tournament-place__place {
      background: linear-gradient(
        90deg,
        #a65035 13.54%,
        #ffbfab 54.17%,
        #a65735 86.98%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      user-select: none;
    }
  }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    > div {
      position: relative;
      height: 50%;
      overflow: hidden;
      &:nth-child(2) {
        svg {
          bottom: 0;
        }
      }
    }
    svg {
      position: absolute;
      width: 100%;
      height: 366px;
    }
  }
  &__info {
    padding: 20px 20px;
  }
  &__place {
    font-size: 32px;
    font-weight: bold;
  }
  &__caption {
    font-size: 20px;
    line-height: 24px;
    color: #ade5d9;
  }
  &__coins {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: #fff;
    vertical-align: top;
    margin-left: 6px;
    img {
      vertical-align: middle;
      margin-bottom: 4px;
      margin-right: 4px;
    }
  }
  &__content {
    position: relative;
    background-color: rgba(0, 255, 254, 0.15);
    padding: 20px;
    min-height: 126px;
    &_skeleton {
      background-color: rgba(0, 255, 254, 0.05);
      > * {
        opacity: 0.3;
      }
    }
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
    padding-right: 70px;
    margin-bottom: 12px;
  }
  &__desc {
    font-size: 18px;
    line-height: 24px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 70px;
    &.fluid {
      padding-right: 0;
    }
    strong {
      font-weight: normal;
      color: #ade5d9;
    }
  }
  &__text {
    display: grid;
    align-items: center;
    height: 100%;
    font-size: 18px;
  }
  &__date {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;
  }
  &__button {
    @include reset-button;
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #ade5d9;
    font-size: 16px;
  }
  &__content {
  }
}

#tournament-place-bg_1 {
  path.border {
    fill: #ba5900;
  }
}
#tournament-place-bg_2 {
  path.border {
    fill: #b4b4b4;
  }
}
#tournament-place-bg_3 {
  path.border {
    fill: #a65035;
  }
}
</style>
